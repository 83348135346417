












































































































import { Collection, Comment, Customer, Fulfillment, Order, OrderItem, Product } from '@/models/internal';
import { ConfigurationService, UserService } from '@/services';
import { Logger } from '@/tools/Logger';
import { Vue, Component } from 'vue-property-decorator';
import { Sync } from 'vuex-pathify';
import { Utility } from '@/tools/Utility';
import { Page } from '@/components/Page.vue';

@Component({
  name: 'Settings',
  components: {
    Page,
  },
})
export default class Settings extends Vue {
  @Sync('context/theme@dark')
  protected isDarkTheme!: boolean;

  protected loading = false;

  protected version: string | null = null;

  protected config: Record<string, any> | null = null;

  private readonly configurationService: ConfigurationService = ConfigurationService.getInstance();

  private readonly userService: UserService = UserService.getInstance();

  private readonly logger: Logger = new Logger({ context: 'ConfigurationsEdit' });

  public created() {
    this.init();
  }

  /**
   * Clear cached ORM data
   */
  protected async onClearCache() {
    await Utility.clearCache(
      [
        Collection,
        Comment,
        Customer,
        Fulfillment,
        Order,
        OrderItem,
        Product,
      ],
      true, // Pass `true` to reload the page after clearing
    );
  }

  /**
   * Save config to database
   */
  protected async onSave(): Promise<any> {
    this.loading = true;
    let configuration = {
      frontend: this.config,
    };
    try {
      await this.configurationService.api.update({
        authentication_token: this.userService.getActiveToken(),
        configuration: configuration,
      });
      await this.configurationService.api.findOne({
        authentication_token: this.userService.getActiveToken(),
      });
      this.$genify.notify('Configuration saved!', 'success');
    } catch (error) {
      this.logger.error(error);
      this.$genify.alert('There was an error saving the Configuration.', 'error');
    } finally {
      this.loading = false;
    }
  }

  private async init() {
    this.loading = true;
    this.version = await Utility.fetchVersion();
    this.config  = await Utility.fetchConfig();
    this.loading = false;
  }
}
